<template>
    <section
        class="flex flex-col xl:flex-row gap-8 lg:gap-12 xl:gap-72 py-14 lg:py-[72px] xl:py-32 px-6 md:px-14 2xl:px-[160px] 3xl:px-[270px] text-white bg-scaling bg-cover bg-center bg-no-repeat xl:min-h-[483px]">
        <div class="flex flex-col gap-6 text-sm font-medium md:text-left uppercase w-full lg:w-1/2">
            {{ $t('What our scaling teams have to say') }}
            <SliderNavigation
                :number-of-slides="slides.length"
                :current-slide-index="currentSlideIndex"
                @set-slide="handleSetSlide" />
        </div>
        <Swiper
            :modules="[Autoplay]"
            :slides-per-view="1"
            :autoplay="{delay: 8000}"
            class="relative w-full flex text-white xl:w-11/12 2xl:w-10/12 3xl:w-9/12 before:flex before:flex-col before:content-quote-icon before:w-7"
            @swiper="onSwiper"
            @slide-change-transition-start="onSlideChange">
            <SwiperSlide v-for="(slide, index) in slides" :key="index">
                <div class="ml-6 flex flex-col gap-6 px-4 relative">
                    <div class="h-full absolute border-l top-0 -left-3" />
                    <span
                        class="font-normal text-[22px] leading-8 lg:text-2xl lg:leading-[34px] 2xl:font-light 2xl:text-[32px] 2xl:leading-[48px]"
                        >{{ slide.text }}</span
                    >
                    <div class="flex flex-col gap-4">
                        <component :is="slide.icon" />
                        <span class="text-sm font-medium uppercase">{{ slide.member }}</span>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </section>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import SliderNavigation from '@/Components/slider/slider-buttons/slider-navigation.component.vue';
import {CosmicoIcon} from '@/images/icons';
import {Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/vue';

const currentSlideIndex = ref(0);
let swiperInstance: typeof Swiper | null = null;

const slides = [
    {
        text: 'JAGAAD has been excellent at meeting deadlines, collaborating, and communicating with our client.',

        member: 'Gianluca Nigro, Business Developer, Cosmico',
        icon: CosmicoIcon,
    },
    {
        text: 'Working with partners like JAGAAD, who are more organized than local vendors, is impressive.',

        member: 'Walter Miele, CEO, Red&Blue',
        icon: CosmicoIcon,
    },
    {
        text: 'The team was perfectly embedded in our organization and internal employees.',

        member: 'Fabio Zecchini, CTO & Co-Founder, TUI Musement',
        icon: CosmicoIcon,
    },
    {
        text: 'One of the strengths of JAGAAD is their ability to find areas to improve and take action to do so.',

        member: 'Valentina Colazzo, Sole Administrator, The Cooking Hacks',
        icon: CosmicoIcon,
    },
    {
        text: "They’re great people, and it's lovely to work with them.",

        member: 'Matteo Pittarello, CEO, Strooka',
        icon: CosmicoIcon,
    },
];

function handleSetSlide(index: number) {
    currentSlideIndex.value = index;
}

function onSlideChange(swiper: any) {
    currentSlideIndex.value = swiper.activeIndex;
}

watch(currentSlideIndex, (newIndex: number) => {
    swiperInstance?.slideTo(newIndex);
});

function onSwiper(swiper: any) {
    swiperInstance = swiper;
}
</script>
