<template>
    <section
        id="technologies"
        class="py-14 lg:py-[72px] 2xl:py-[120px] h-full bg-gradient-to-t from-primary-content/100 to-primary-content/0">
        <div class="flex flex-col items-center mx-6 mb-14 lg:mb-[72px] 2xl:mb-24 lg:mx-12 xl:mx-56 2xl:mx-24 3xl:mx-72">
            <span
                class="text-sm font-semibold bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text mb-8 uppercase">
                {{ $t('Not just a team but an ecosystem') }}
            </span>
            <div class="flex flex-col gap-6 text-center 2xl:mx-40">
                <span class="text-[32px] leading-10 lg:text-[38px] lg:leading-[50px] 2xl:text-5xl 2xl:leading-[60px]">
                    {{ $t('We identify top-tier tech talent to expand your team strategically') }}
                </span>

                <span class="text-sm uppercase">
                    {{ $t('you name it, we have it') }}
                </span>
            </div>
        </div>
        <div
            class="flex flex-col justify-center gap-[72px] xl:flex-row mx-auto px-6 md:px-14 2xl:px-[160px] 3xl:px-[270px] w-full">
            <div class="flex flex-col w-full xl:w-[60%]">
                <Disclosure
                    v-for="(domain, index) in domains"
                    :key="domain.title"
                    v-slot="{open, close}"
                    :default-open="index === 0"
                    as="section"
                    class="border-b border-base-200 py-4">
                    <DisclosureButton
                        :ref="(el) => (disclosure[index] = close)"
                        class="flex w-full items-center justify-between rounded-lg pr-4 py-2 text-left font-normal text-2xl leading-[34px]"
                        :class="{
                            'text-primary': open,
                        }"
                        @click="hideOther(index)">
                        <div class="flex gap-7 items-center">
                            <div class="p-2">
                                <component :is="domain.icon" class="size-8" />
                            </div>
                            <span>{{ domain.title }}</span>
                        </div>

                        <ChevronUpIcon :class="open ? 'rotate-180 transform' : ''" class="size-5 min-w-5" />
                    </DisclosureButton>
                    <DisclosurePanel class="flex flex-col gap-6 pb-2 pt-4 text-sm">
                        <span>
                            {{ domain.description }}
                        </span>
                        <div class="flex flex-col gap-4">
                            <span class="text-xl">
                                {{ domain.skillsHeader }}
                            </span>

                            <div class="flex flex-wrap gap-2 px-4">
                                <span
                                    v-for="skill in domain.skills"
                                    :key="skill"
                                    class="py-1 px-4 rounded-full border border-base-200">
                                    {{ skill }}
                                </span>
                            </div>
                        </div>
                    </DisclosurePanel>
                </Disclosure>
            </div>
            <div class="rounded-2xl relative w-full xl:w-[40%] h-[638px]">
                <video
                    class="absolute h-full w-full object-cover object-top rounded-2xl"
                    autoplay
                    muted
                    loop
                    disablePictureInPicture
                    playsinline>
                    <source src="/videos/top_tier.mp4" type="video/mp4" />
                </video>
                <div class="absolute inset-0 bg-[#0E1924] opacity-30 h-full rounded-2xl" />
                <div
                    class="absolute rounded-2xl top-0 bg-base-content text-white text-2xl lg:text-3xl p-8 lg:px-14 lg:py-12">
                    {{
                        $t(
                            'Our powerful yet easy-to-use system frees up your time to focus on what truly matters: business success.',
                        )
                    }}
                </div>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import {ref} from 'vue';
import {TemplateIcon} from '@/images/icons';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import {ChevronUpIcon} from '@heroicons/vue/20/solid';
import {ClipboardDocumentListIcon, CodeBracketIcon, UserCircleIcon} from '@heroicons/vue/24/outline';

const disclosure = ref<Function[]>([]);

const hideOther = (id: number) => {
    disclosure.value.filter((d, i) => i !== id).forEach((c: Function) => c());
};

const domains = [
    {
        icon: CodeBracketIcon,
        title: 'Developers & AI Engineers',
        description: 'Sophisticated full-stack development using cutting-edge tools and frameworks.',
        skillsHeader: 'Development techs',
        skills: [
            'Java',
            'Kotlin',
            'PHP',
            'C/C++',
            'Golang',
            'Kotlin',
            'Android',
            'IOS',
            'Angular',
            'React',
            'Django',
            'GANTT Chart',
            'PERT Chart',
            'Unit Testing',
            'UML Diagrams',
            'Algorithm Analysis',
            'Profiling',
            'Agile',
            '...',
        ],
    },
    {
        icon: TemplateIcon,
        title: 'Designers & User Experience (UX)',
        description:
            'Design solutions tailored to overcome business challenges with attention to user experience and customer journey from discovery, acquisition to conversion and retention.',
        skillsHeader: 'Design tools/skills',
        skills: [
            'Figma',
            'Miro',
            'Sketch',
            'Photoshop',
            'Adobe XD',
            'InVision',
            'Justinmind',
            'Adobe After Effects',
            'Adobe illustrator',
            'UXcam',
            'UXpin',
            'Marvel',
            'User-personas',
            'Customer journeys',
            'Competitive audit',
            'Design thinking',
            'A/B testing',
            'Design systems',
            '...',
        ],
    },
    {
        icon: ClipboardDocumentListIcon,
        title: 'Project managers',
        description:
            'Project managers who strategically guide teams to overcome business challenges by optimizing user experiences and driving customer journeys from initial discovery to long-term loyalty.',
        skillsHeader: 'Project managers tools/skills',
        skills: [
            'Cleos',
            'Asana',
            'Clickup',
            'basecamp',
            'Jira system',
            'Trello',
            'Wrike',
            'Monday',
            'Teamwork',
            'SWOT',
            'Decision Matrix',
            'SCRUM master',
            'SOAR',
            "Porter's Five Forces",
            '...',
        ],
    },
    {
        icon: UserCircleIcon,
        title: 'Human resources & Operations',
        description:
            'Manage and optimize processes, drive employee engagement, recruitment, talent management, and ensure coordination across departments to align HR and operations with business goals.',
        skillsHeader: 'Human resources & Operations tools/skills',
        skills: [
            'Workday',
            'BambooHR',
            'SAP SuccessFactor',
            'Greenhouse',
            'Zenefits',
            'Payroll Management',
            'Benefits Administration',
            'Employee Engagement',
            'Performance Reviews',
            'Talent Acquisition',
            'HR Analytics',
            'Risk Management',
            'Process Automation',
            'Strategic Planning',
            'Operational Efficiency',
            '...',
        ],
    },
];
</script>
